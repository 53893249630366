
import {
  formatDateNumber,
  parsingErrorResponse
} from "@/app/infrastructures/misc/Utils";
import { DexAssessmentController } from "@/app/ui/controllers/DexAssessmentController";
import { SummaryBacklog } from "@/domain/entities/DexAssessment";
import { ApiRequestList } from "@/domain/entities/MainApp";
import { ResponsePayloadV2 } from "@/domain/entities/ResponsePayload";
import { Vue, Options, prop } from "vue-class-component";

class Props {
  courierPhoneNumber = prop<string>({
    type: String,
    default: ""
  });
  courierName = prop<string>({
    type: String,
    default: ""
  });
  modelValue = prop<boolean>({
    type: Boolean,
    default: false
  });
}

@Options({
  emits: ["update:modelValue"]
})
export default class PopupDetailCourier extends Vue.with(Props) {
  mounted() {
    this.fetchData();
  }
  onCloseModal() {
    this.$emit("update:modelValue", false);
  }
  forceCloseDialog() {
    this.$emit("update:modelValue", false);
  }
  dashboardData: ResponsePayloadV2 = new ResponsePayloadV2();
  async fetchData() {
    const {
      startDate,
      endDate,
      reasonCode,
      city
    } = DexAssessmentController.filter;

    try {
      this.dashboardData.loading = true;
      const res = await DexAssessmentController.getSummaryByReason(
        new ApiRequestList({
          startDate: formatDateNumber(startDate),
          endDate: formatDateNumber(endDate),
          groupBy: "courier",
          reasonDex: reasonCode.toString(),
          "3lc": city?.code,
          courierPhone: this.courierPhoneNumber
        })
      );

      const data: SummaryBacklog = res.data;
      const height = data.labels.length * 45;
      this.byReasonChart = {
        ...this.byReasonChart,
        series: data.datasets.map((item: any) => ({
          name: item.label,
          data: item.data
        })),
        categories: data.labels,
        height: height <= 175 ? 175 : height,
        total: data.totalTicket
      };
    } catch (error) {
      this.dashboardData.message = parsingErrorResponse(error).type;
    } finally {
      this.dashboardData.loading = false;
    }
  }

  byReasonChart = {
    series: [] as any[],
    categories: [] as any[],
    height: 0,
    color: ["#58C5E2", "#ECB888", "#7ACB90", "#D4797C"],
    rounded: false,
    barHeight: "50%",
    total: 0
  };

  get total(): string {
    return `${this.byReasonChart.total} Tiket`;
  }
}
