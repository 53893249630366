
import { Vue, Options, prop } from "vue-class-component";
import CardDashboardClient from "@/app/ui/views/dashboard/component/client/component/card-dashboard-client.vue";
import AreaChart from "@/app/ui/components/chart/area-bar/index.vue";
import { DexAssessmentController } from "@/app/ui/controllers/DexAssessmentController";
import { ApiRequestList } from "@/domain/entities/MainApp";
import { formatDateNumber, parsingErrorResponse } from "@/app/infrastructures/misc/Utils";
import { SummaryTotalDexPerDay } from "@/domain/entities/DexAssessment";

class Props {
  ticketType = prop<string>({
    type: String,
    default: "l1_l2"
  });
}

@Options({
  components: {
    CardDashboardClient,
    AreaChart
  }
})
export default class SectionTicketPerDay extends Vue.with(Props) {
  get filter() {
    return DexAssessmentController.filter;
  }

  async fetchData() {
    const { startDate, endDate } = this.filter;

    try {
      this.isLoading = true;
      const res = await DexAssessmentController.getTotalTicketPerDay(
        new ApiRequestList({
          startDate: formatDateNumber(startDate),
          endDate: formatDateNumber(endDate),
          ticketType: this.ticketType,
          reasonDex: this.filter.reasonCode.toString(),
          "3lc": this.filter.city?.code
        })
      );
      this.chart = res.data;
    } catch (err) {
      this.errorMessage = parsingErrorResponse(err).type;
    } finally {
      this.isLoading = false;
    }
  }
  chart = new SummaryTotalDexPerDay();
  isLoading = false;
  errorMessage = false;

  get titleChart() {
    return `Total Tiket DEX ${
      this.ticketType === "dispute" ? "Sanggah L2" : ""
    } Per Hari`;
  }
}
