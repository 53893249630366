import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, mergeProps as _mergeProps, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex flex-row justify-between items-center"
}
const _hoisted_2 = { class: "w-1/4" }
const _hoisted_3 = { class: "border-l border-r p-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TooltipV2 = _resolveComponent("TooltipV2")!
  const _component_CustomDropdown = _resolveComponent("CustomDropdown")!
  const _component_TableV2 = _resolveComponent("TableV2")!
  const _component_Card = _resolveComponent("Card")!
  const _component_PopupDetailCourier = _resolveComponent("PopupDetailCourier")!

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode(_component_Card, { class: "flex flex-col gap-4 p-4" }, {
      default: _withCtx(() => [
        (_ctx.showOrderFilter)
          ? (_openBlock(), _createBlock("div", _hoisted_1, [
              _createVNode(_component_TooltipV2, {
                text: _ctx.title,
                fontSize: 14,
                fontWeight: "semibold"
              }, null, 8, ["text"]),
              _createVNode("div", _hoisted_2, [
                _createVNode(_component_CustomDropdown, {
                  selectedItem: _ctx.filter.orderBy,
                  options: _ctx.ordersTopConsoleDispute,
                  placeholder: "Urutkan",
                  onSelect: _ctx.selectOrderConsoleDispute,
                  class: "mt-2"
                }, null, 8, ["selectedItem", "options", "onSelect"])
              ])
            ]))
          : (_openBlock(), _createBlock(_component_TooltipV2, {
              key: 1,
              text: _ctx.title,
              fontSize: 14,
              fontWeight: "semibold"
            }, null, 8, ["text"])),
        _createVNode(_component_TableV2, {
          borderTop: false,
          borderBottom: false,
          pinnedSubstractHeight: "200px",
          infiniteScroll: !_ctx.filter.clickFilter,
          hidePagination: "",
          isNoPaddingBottom: "",
          isSpaceTop: false,
          totalPercentFreeze: 15,
          loading: _ctx.partnerData.loading,
          columns: _ctx.columns(false),
          data: _ctx.partnerData.data,
          isEmpty: _ctx.isEmpty,
          pagination: _ctx.partnerData.pagination,
          mptyHeaderMessage: "Tidak Ada Top 10 Konsol Terbanyak",
          mptyMessage: "Mulai buat Top 10 Konsol Terbanyak dan cek daftarnya disini",
          onRequest: _cache[2] || (_cache[2] = () => _ctx.fetchSummaryTopDex('partner')),
          onTryAgain: () => _ctx.fetchSummaryTopDex('partner'),
          isRightFreeze: "",
          totalColumnFreeze: 2
        }, {
          "expand-row": _withCtx(({item}) => [
            _createVNode("div", _hoisted_3, [
              _createVNode(_component_TableV2, {
                isStickyHeader: false,
                borderTop: false,
                borderBottom: false,
                infiniteScroll: "",
                hidePagination: "",
                isNoPaddingBottom: "",
                isSpaceTop: false,
                bgHeader: "bg-aqua-lp-200",
                totalPercentFreeze: 15,
                zIndexHeader: "z-0",
                loading: _ctx.courierData.loading,
                columns: _ctx.columns(true),
                data: item.courierData,
                isEmpty: item.courierData.length,
                pagination: _ctx.courierData.pagination,
                mptyHeaderMessage: "Tidak Ada Kurir",
                mptyMessage: "Mulai buat Kurir dan cek daftarnya disini",
                onRequest: _cache[1] || (_cache[1] = () => _ctx.fetchSummaryTopDex('courier')),
                onTryAgain: () => _ctx.fetchSummaryTopDex('courier'),
                isRightFreeze: "",
                totalColumnFreeze: 1
              }, null, 8, ["loading", "columns", "data", "isEmpty", "pagination", "onTryAgain"])
            ])
          ]),
          _: 1
        }, 8, ["infiniteScroll", "loading", "columns", "data", "isEmpty", "pagination", "onTryAgain"])
      ]),
      _: 1
    }),
    (_ctx.popupDetail)
      ? (_openBlock(), _createBlock(_component_PopupDetailCourier, _mergeProps({
          key: 0,
          modelValue: _ctx.popupDetail,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (_ctx.popupDetail = $event))
        }, _ctx.dataToDetail), null, 16, ["modelValue"]))
      : _createCommentVNode("", true)
  ], 64))
}