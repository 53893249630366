import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "mt-3" }
const _hoisted_2 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Skeleton = _resolveComponent("Skeleton")!
  const _component_AreaChart = _resolveComponent("AreaChart")!
  const _component_CardDashboardClient = _resolveComponent("CardDashboardClient")!

  return (_openBlock(), _createBlock(_component_CardDashboardClient, { title: _ctx.titleChart }, {
    default: _withCtx(() => [
      _createVNode("div", _hoisted_1, [
        (_ctx.isLoading)
          ? (_openBlock(), _createBlock("div", _hoisted_2, [
              _createVNode(_component_Skeleton, {
                class: "mt-2",
                height: "1rem"
              }),
              _createVNode(_component_Skeleton, {
                class: "mt-2",
                height: "20rem"
              })
            ]))
          : (!_ctx.isLoading && _ctx.chart.datasets.length && _ctx.chart.labels.length)
            ? (_openBlock(), _createBlock(_component_AreaChart, {
                key: 1,
                seriesData: _ctx.chart.datasets,
                categoriesData: _ctx.chart.labels,
                isPercentage: false
              }, null, 8, ["seriesData", "categoriesData"]))
            : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }, 8, ["title"]))
}