
import formatDateNumber from "@/app/infrastructures/misc/common-library/FormatDateNumber";
import parsingErrorResponse from "@/app/infrastructures/misc/common-library/ParsingErrorResponse";
import { DexAssessmentController } from "@/app/ui/controllers/DexAssessmentController";
import { SummaryTopDex } from "@/domain/entities/DexAssessment";
import { ApiRequestList, OptionsClass } from "@/domain/entities/MainApp";
import { ResponsePayloadV2 } from "@/domain/entities/ResponsePayload";
import { Vue, prop, Options } from "vue-class-component";
import PopupDetailCourier from "./popup-detail-courier.vue";
import { formatPriceWithoutCurrency } from "@/app/infrastructures/misc/Utils";

class Props {
  title = prop<string>({
    default: "",
    type: String
  });
  showOrderFilter = prop<boolean>({
    default: false,
    type: Boolean
  });
}
@Options({
  components: {
    PopupDetailCourier
  }
})
export default class SectionTableExpand extends Vue.with(Props) {
  partnerData = new ResponsePayloadV2();
  courierData = new ResponsePayloadV2();
  partnerDetail = new SummaryTopDex();

  get filter() {
    return DexAssessmentController.filter;
  }
  async fetchSummaryTopDex(type: "partner" | "courier") {
    try {
      const { startDate, endDate } = DexAssessmentController.filter;
      let payload = new ApiRequestList({
        page: this.partnerData.pagination.page,
        limit: this.partnerData.pagination.limit,
        startDate: formatDateNumber(startDate),
        endDate: formatDateNumber(endDate),
        listType: type,
        isDispute: this.showOrderFilter,
        reasonDex: this.filter.reasonCode.toString(),
        "3lc": this.filter.city?.code
      });
      if (type === "partner") {
        this.partnerData.loading = true;
        if (this.showOrderFilter) {
          payload = new ApiRequestList({
            ...payload,
            orderBy: this.filter.orderBy?.value
          });
        }
      } else {
        this.courierData.loading = true;
        this.partnerData.data[this.partnerDetail.index].courierData = [];
        payload = new ApiRequestList({
          ...payload,
          partnerId: this.partnerDetail.partnerId
        });
      }
      const res = await DexAssessmentController.getSummaryTopDex(payload);

      if (type === "partner") {
        this.onPartnerData(res);
      } else {
        this.onCourierData(res);
      }
    } catch (err) {
      this.partnerData.message = parsingErrorResponse(err).type;
    } finally {
      this.filter.clickFilter = false;
      this.partnerData.loading = false;
      this.courierData.loading = false;
    }
  }
  onPartnerData(res: ResponsePayloadV2) {
    const { clickFilter } = DexAssessmentController.filter;

    let tempData: SummaryTopDex[] = [];
    if (clickFilter) {
      tempData = res.data;
    } else {
      tempData = [...this.partnerData.data, ...res.data];
    }
    this.partnerData = new ResponsePayloadV2({
      pagination: res.pagination,
      data: tempData
    });
  }
  onCourierData(res: ResponsePayloadV2) {
    const { clickFilter } = DexAssessmentController.filter;

    let tempData: SummaryTopDex[] = [];
    if (clickFilter) {
      tempData = res.data;
    } else {
      tempData = [
        ...this.partnerData.data[this.partnerDetail.index].courierData,
        ...res.data
      ];
    }
    this.partnerData.data[this.partnerDetail.index].courierData = tempData;
  }

  columnData(params: { name: string; key: string; styleHead?: string }) {
    const { name, key } = params;

    const render = (item: any) => {
      switch (key) {
        case "percentageInvalid":
          return `<span class='text-left text-black-lp-300 flex justify-start'>${this.formatDecimal(
            item.percentageInvalid
          )}%</span>`;
        default:
          return `<span class='text-left text-black-lp-300 flex justify-start'>${item[key]}</span>`;
      }
    };
    return {
      name,
      styleHead: `${params.styleHead} text-left whitespace-no-wrap`,
      advancedStyle: (item: SummaryTopDex) =>
        item.expand ? "border-t border-b" : "",
      render
    };
  }
  get isDisputeColumns() {
    return [
      {
        name: "No",
        styleHead: "w-16 text-left whitespace-no-wrap",
        advancedStyle: (item: SummaryTopDex) =>
          item.expand ? "border-t border-b border-l rounded-tl-lg" : "",
        render: (_: any, index: number) => {
          return `<div class="overflow-ellipsis text-left">${index +
            1 +
            this.partnerData.pagination.limit *
              (this.partnerData.pagination.page - 1)}</div>`;
        }
      },
      this.columnData({ name: "Nama Konsol", key: "name", styleHead: "w-52" }),
      this.columnData({
        name: "Total DEX",
        key: "totalDex",
        styleHead: "w-32"
      }),
      this.columnData({
        name: "Tidak Sesuai",
        key: "totalInvalid",
        styleHead: "w-32"
      }),
      this.columnData({
        name: "Total Sanggah",
        key: "totalDispute",
        styleHead: "w-32"
      }),
      this.columnData({
        name: "Pengajuan",
        key: "totalRequested",
        styleHead: "w-32"
      }),
      this.columnData({
        name: "Disetujui",
        key: "totalApprove",
        styleHead: "w-32"
      }),
      this.columnData({
        name: "Tidak Disetujui",
        key: "totalRejected",
        styleHead: "w-32"
      }),
      this.columnData({
        name: "Persentase Tidak Sesuai",
        key: "percentageInvalid",
        styleHead: "w-48"
      }),
      ...this.lastDisputeColumns
    ];
  }

  get defaultColumns() {
    return [
      {
        name: "No",
        styleHead: "w-16 text-left whitespace-no-wrap",
        advancedStyle: (item: SummaryTopDex) =>
          item.expand ? "border-t border-b border-l rounded-tl-lg" : "",
        render: (_: any, index: number) => {
          return `<div class="overflow-ellipsis text-left">${index +
            1 +
            this.partnerData.pagination.limit *
              (this.partnerData.pagination.page - 1)}</div>`;
        }
      },
      {
        name: "3LC",
        styleHead: "w-52 text-left whitespace-no-wrap",
        advancedStyle: (item: SummaryTopDex) =>
          item.expand ? "border-t border-b" : "",
        render: (item: SummaryTopDex) =>
          `<span class='text-left text-black-lp-300 flex justify-start'>${item.destination}</span>`
      },
      {
        name: "Total STT",
        styleHead: "w-32 text-left whitespace-no-wrap",
        advancedStyle: (item: SummaryTopDex) =>
          item.expand ? "border-t border-b" : "",
        render: (item: SummaryTopDex) =>
          `<span class='text-left text-black-lp-300 flex justify-start'>${item.totalStt}</span>`
      },
      {
        name: "Total DEX",
        styleHead: "w-32 text-left whitespace-no-wrap",
        advancedStyle: (item: SummaryTopDex) =>
          item.expand ? "border-t border-b" : "",
        render: (item: SummaryTopDex) =>
          `<span class='text-left text-black-lp-300 flex justify-start'>${item.totalDex}</span>`
      },
      {
        name: "Tidak Sesuai",
        styleHead: "w-32 text-left whitespace-no-wrap",
        advancedStyle: (item: SummaryTopDex) =>
          item.expand ? "border-t border-b" : "",
        render: (item: SummaryTopDex) =>
          `<span class='text-left text-black-lp-300 flex justify-start'>${item.totalInvalid}</span>`
      }
    ];
  }

  get lastDisputeColumns() {
    return [
      {
        name: "Persentase Sanggah",
        styleHead: "w-48 text-left whitespace-no-wrap",
        advancedStyle: (item: SummaryTopDex) =>
          item.expand ? "border-t border-b" : "",
        render: (item: SummaryTopDex) =>
          `<span class='text-left text-black-lp-300 flex justify-start'>${this.formatDecimal(
            item.percentageDispute
          )}%</span>`
      },
      {
        name: "Persentase Pengajuan",
        styleHead: "w-48 text-left whitespace-no-wrap",
        advancedStyle: (item: SummaryTopDex) =>
          item.expand ? "border-t border-b" : "",
        render: (item: SummaryTopDex) =>
          `<span class='text-left text-black-lp-300 flex justify-start'>${this.formatDecimal(
            item.percentageRequested
          )}%</span>`
      },
      {
        name: "Persentase Disetujui",
        styleHead: "w-48 text-left whitespace-no-wrap",
        advancedStyle: (item: SummaryTopDex) =>
          item.expand ? "border-t border-b" : "",
        render: (item: SummaryTopDex) =>
          `<span class='text-left text-black-lp-300 flex justify-start'>${this.formatDecimal(
            item.percentageApproved
          )}%</span>`
      },
      {
        name: "Persentase Tidak Disetujui",
        styleHead: "w-48 text-left whitespace-no-wrap",
        advancedStyle: (item: SummaryTopDex) =>
          item.expand ? "border-t border-b" : "",
        render: (item: SummaryTopDex) =>
          `<span class='text-left text-black-lp-300 flex justify-start'>${this.formatDecimal(
            item.percentageRejected
          )}%</span>`
      }
    ];
  }

  columns(courier: boolean, isDispute = this.showOrderFilter) {
    let data: any[] = this.defaultColumns.concat(this.nextColumns);
    if (isDispute) {
      data = this.isDisputeColumns.concat(this.nextColumns.slice(-2));
    }
    if (courier) {
      return this.courierColumns(data);
    }
    return data;
  }
  get nextColumns(): any {
    return [
      {
        name: "Persentase DEX",
        styleHead: "w-48 text-left whitespace-no-wrap",
        advancedStyle: (item: SummaryTopDex) =>
          item.expand ? "border-t border-b" : "",
        render: (item: SummaryTopDex) =>
          `<span class='text-left text-black-lp-300 flex justify-start'>${this.formatDecimal(
            item.percentageDex
          )}%</span>`
      },
      {
        name: "Persentase Tidak Sesuai",
        styleHead: "w-48 text-left whitespace-no-wrap",
        advancedStyle: (item: SummaryTopDex) =>
          item.expand ? "border-t border-b" : "",
        render: (item: SummaryTopDex) =>
          `<span class='text-left text-black-lp-300 flex justify-start'>${this.formatDecimal(
            item.percentageInvalid
          )}%</span>`
      },
      {
        name: "Atur",
        key: "button_column",
        styleHead: "w-32 text-left",
        advancedStyle: (item: SummaryTopDex) =>
          item.expand ? "border-t border-b" : "",
        styleButton: (item: SummaryTopDex) => ({
          flat: true,
          compact: true,
          title: "Lihat Kurir",
          textColor: "red-lp-100",
          color: "red-lp-100",
          iconV2: true,
          icon: item.expand ? "chevron-up" : "chevron-down",
          small: true,
          clickFunction: this.onExpandCourier
        })
      },
      {
        name: "",
        key: "button_column",
        styleHead: "w-16 text-left",
        advancedStyle: (item: SummaryTopDex) =>
          item.expand ? "border-t border-b border-r rounded-tr-lg" : "",
        styleButton: (__: SummaryTopDex, _: number) => ({
          textColor: "red-lp-100",
          color: "red-lp-100",
          outline: true,
          small: true,
          iconV2: true,
          icon: "download-new",
          clickFunction: this.onDownload
        })
      }
    ];
  }
  courierColumns(data: any[]) {
    data.shift();
    data.pop();
    return data.map((e: any, i: number) => {
      if (!i) {
        return {
          name: "Nama Kurir",
          styleHead: "w-64 text-left whitespace-no-wrap",
          render: (item: SummaryTopDex) =>
          `<span class='text-left text-black-lp-300 flex justify-start'>${item.name}</span>`
        };
      } else if (i === data.length - 1) {
        return {
          name: e.name,
          key: e.key,
          styleHead: "w-44 text-left",
          styleButton: (__: SummaryTopDex, _: number) => ({
            flat: true,
            compact: true,
            title: "Detail Alasan",
            textColor: "red-lp-100",
            color: "red-lp-100",
            small: true,
            clickFunction: (item: any) => {
              this.popupDetail = true;
              this.dataToDetail = {
                courierPhoneNumber: item?.courierPhone,
                courierName: item?.name
              };
            }
          })
        };
      }
      return e;
    });
  }

  formatDecimal(value: number) {
    return formatPriceWithoutCurrency(value);
  }

  get isEmptyDate(): boolean {
    return !this.filter.startDate && !this.filter.endDate;
  }
  get isEmpty(): boolean {
    return (
      this.isEmptyDate &&
      !this.partnerData.data.length &&
      !this.filter.reasonCode &&
      !this.filter.reasonCode?.length &&
      !this.filter.city &&
      !this.filter.city?.code
    );
  }

  popupDetail = false;
  dataToDetail = {
    courierPhoneNumber: "",
    courierName: ""
  };
  onExpandCourier(item: SummaryTopDex, index: number) {
    if (!item.expand) {
      this.partnerData.data[index].expand = true;
      if (!item.courierData.length) {
        this.partnerDetail = new SummaryTopDex({ ...item, index });
        this.fetchSummaryTopDex("courier");
      }
    } else {
      this.partnerData.data[index].expand = false;
    }
  }
  onDownload(item: SummaryTopDex) {
    DexAssessmentController.filter.partnerId = item.partnerId;
    DexAssessmentController.filter.type = this.showOrderFilter
      ? "dispute"
      : "l1_l2";
    DexAssessmentController.getDownload(
      "summary-breakdown-consolidator-download"
    );
  }

  get ordersTopConsoleDispute(): OptionsClass[] {
    return [
      new OptionsClass({
        name: "STT DEX Terbanyak",
        value: "request_dex"
      }),
      new OptionsClass({
        name: "Pengajuan Terbanyak",
        value: "request_dispute"
      })
    ];
  }

  selectOrderConsoleDispute(data: OptionsClass) {
    Object.assign(this.filter, { orderBy: data, clickFilter: true });
    this.fetchSummaryTopDex("partner");
  }
}
